<template>
  <div class="goods-sell">
    <div class="st-wrapper">
      <div class="card goods-name">
        <div class="head">
          <span class="name"> 商品名称</span>
          <div class="right" @click="toGoods" v-if="fromMode === 0">
            <input type="text" placeholder="请输入" v-model="goodModel.goods_name"/>
            <van-icon class="icon" name="arrow" color="#999" />
          </div>
          <div class="right" v-if="fromMode === 1">
            <input type="text" placeholder="请输入" v-model="goodModel.goods_name"/>
          </div>
        </div>
        <div class="head" @click="merchantDropDown()">
          <span class="name">类目</span>
          <div class="right">
            <span  style="color:#333;">{{category.category_name?category.category_name:'请选择'}}</span>
            <van-icon class="icon" name="arrow" color="#999" />
          </div>
        </div>
        <div class="body">
          <div class="upload">
            <van-uploader v-model="fileList" :after-read="afterRead" @delete="beforeDelete">
              <div class="upload-wrap">
                <img src="../../../assets/images/icon/entry-upload.png" alt="" />
              </div>
            </van-uploader>
            <span v-if="fileList.length === 0" style="margin-left:.2rem;color:#bebebe;">请上传商品图片</span>
          </div>
        </div>
      </div>
      <div class="card specs-list">
        <div class="body">
          <div class="spec">
            <div class="head">
                <span class="name" style="padding-top:0;">商品价格</span>
            </div>
            <div class="middle">
              <div class="wrap">
                <div class="freight">
                  <input type="number" placeholder="输入单价" v-model="spec_price" @input="bindEdit"/><span class="icon" style="padding-right:0.3rem;">元/公斤</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer-wrap">
        <button
          class="btn no-sell"
          @click="handleUp(0)"
        >
          <span>暂不上架</span></button>
        <button
          class="btn sell"
          @click="handleUp(1)"
        >
          <span>确认上架</span>
        </button>
      </div>
    </div>
    <!-- 弹框 -->
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="categoryColumns"
        @cancel="showPicker = false"
        @confirm="onConfirm"
        value-key="category_name"
      />
    </van-popup>
    <guide />
  </div>
</template>

<script>
import { Uploader, Badge, Icon, Stepper, Dialog, Popup, Picker, Toast, Popover } from 'vant'
import Guide from '../../../components/guide/Index'
// import { mapState } from 'vuex'
export default {
  data () {
    return {
      fromMode: 0, // 0代表新增，1代表修改
      fileList: [],
      modifyFileList: [],
      fileModel: {
        gift_id: 0,
        img_id: 0,
        original: '',
        sort: 0
      },
      modifyFileModel: {
        url: ''
      },
      skuLength: 0,
      showPicker: false,
      merchantVal: '',
      info: {},
      categoryColumns: [],
      category: {},
      spec_price: null,
      unitPicer: false,
      unitColumns: [
        {
          text: '盒',
          checked: false
        },
        {
          text: '包',
          checked: false
        },
        {
          text: '捆',
          checked: false
        },
        {
          text: '根',
          checked: false
        },
        {
          text: '袋',
          checked: false
        },
        {
          text: '箱',
          checked: false
        },
        {
          text: '罐',
          checked: false

        },
        {
          text: '头',
          checked: false

        }
      ],
      weightUnit: [
        {
          id: 'JIN',
          text: '斤'
        },
        {
          id: 'KILO',
          text: '公斤'
        },
        {
          id: 'GRAMME',
          text: '克'
        },
        {
          id: 'TAEL',
          text: '两'
        }
      ],
      unit: {
        goods_unit: '', // 自定义单位
        goods_weightUnit: ''
      },
      weight: {},
      goodWeight: null,
      cusUnitList: [],
      unitCustom: {
        text: ''
      },
      unitText: '',
      weightPicker: false,
      value: 0,
      unit_index: 0,
      modifyIndex: 0,
      goodModel: {
        category_id: 0,
        category_name: '',
        farmers_market_goods_id: '',
        farmers_market_id: '',
        farmers_market_merchant_id: '',
        farmers_market_name: '',
        goods_gallery_list: [],
        goods_id: 0,
        goods_name: '',
        logistics_id: 0,
        logistics_name: '',
        market_enable: 0,
        seller_id: 0,
        seller_name: '',
        ship_mode: 0,
        sku_list: []
      },
      skuList: [],
      skuModel: {
        cost: 0,
        description: '',
        enable_quantity: 0,
        goods_type: '',
        goods_unit: '',
        is_wholesale_goods: 1,
        minimum_quantity: 1,
        mprice: 0,
        price: 0,
        quantity: 0,
        sn: '',
        spec_list: [
          {
            spec_name: '',
            spec_value: ''
          },
          {
            spec_name: '起批量',
            spec_value: ''
          }
        ],
        template_id: 0,
        weight: 0
      },
      orgStartVal: 0,
      modifySkuList: [],
      modifySkuModel: {
        goods_unit: '', // 自定义单位
        spec_name: '',
        goods_weightUnit: '',
        goods_singleWeight: null,
        hash_code: 0,
        isEdit: false,
        spec_list: [
          {
            spec_startValue: '',
            spec_price: '',
            sku_id: 0
          }
        ],
        weight: 0
      },
      modifySpecValueModel: {
        sku_id: 0,
        spec_startValue: '',
        spec_price: ''
      }
    }
  },
  components: {
    [Uploader.name]: Uploader,
    [Badge.name]: Badge,
    [Icon.name]: Icon,
    [Stepper.name]: Stepper,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Popover.name]: Popover,
    Guide
  },
  created () {
    this.getCategories()
    console.log(this.$route.params)
    if (!this.$route.params.info) {
      this.$router.go(-1)
    }
    this.info = this.$route.params.info
    this.fromMode = this.$route.params.mode
    this.goodModel.goods_name = this.$route.params.good_name ? this.$route.params.good_name : ''
    this.category.shop_category_id = this.$route.params.category_id ? this.$route.params.category_id : null
    this.category.category_name = this.$route.params.category_name ? this.$route.params.category_name : ''
    if (this.$route.params.imgList) {
      const fileList = this.$route.params.imgList
      fileList.forEach((item, index) => {
        const url = item
        const fileModel = {
          original: '',
          sort: 0,
          img_id: -1
        }
        const vModel = {
          url: ''
        }
        fileModel.original = url
        vModel.url = url
        this.fileList.push(vModel)
        this.modifyFileList.push(fileModel)
      })
    }
    this.unitColumns = localStorage.getItem('custom_unit') ? this.unitColumns.concat(JSON.parse(localStorage.getItem('custom_unit'))) : this.unitColumns
    this.cusUnitList = JSON.parse(localStorage.getItem('custom_unit'))
    const emptyList = this.getEmptyList()
    this.skuList = emptyList.skuList
    this.modifySkuList = emptyList.modifySkuList
  },
  methods: {
    afterRead (file) {
      const imgfile = file
      const formData = new FormData()
      formData.append('file', imgfile.file, imgfile.file.name)
      this.$api.goods.uploader(formData).then(res => {
        console.log(res)
        const item = this.fileModel
        item.img_id = -1
        item.original = res.data.url
        item.sort = 0
        this.modifyFileList.push(item)
      })
      console.log(this.modifyFileList)
    },
    beforeDelete (file, detail) {
      this.modifyFileList.splice(detail.index, 1)
      console.log(this.modifyFileList)
    },
    // 初始化一个空列表或已知列表
    getEmptyList () {
      if (this.fromMode === 0) {
        const skuList = [{ ...this.skuModel }]
        const modifySkuList = [{ ...this.modifySkuModel }]
        return { skuList, modifySkuList }
      } else {
        console.log(this.info)
        const goodModel = this.info
        this.goodModel = goodModel
        this.goodModel.goods_id = goodModel.goods_id
        this.skuLength = goodModel.sku_list.length
        this.category.shop_category_id = goodModel.category_id
        this.category.category_name = goodModel.category_name
        const fileList = goodModel.goods_gallery_list
        fileList.forEach((item, index) => {
          const url = item.original
          const fileModel = {
            url: ''
          }
          fileModel.url = url
          this.fileList.push(fileModel)
        })
        this.modifyFileList = goodModel.goods_gallery_list
        const skuList = goodModel.sku_list
        const modifySkuList = []
        let modifySkuItem = {
          goods_unit: '', // 自定义单位
          spec_name: '',
          goods_weightUnit: '',
          goods_singleWeight: null,
          hash_code: 0,
          spec_list: [],
          weight: 0,
          sn: ''
        }
        this.unit.goods_unit = skuList[0].spec_list[0].spec_name
        this.unit.goods_weightUnit = skuList[0].goods_unit
        let tmpVal = skuList[0].spec_list[0].spec_value
        let tmpUnit = skuList[0].spec_list[0].spec_name
        this.spec_price = skuList[0].price
        skuList.forEach((item, index) => {
          if (item.spec_list[0].spec_value === tmpVal && item.spec_list[0].spec_name === tmpUnit) {
            modifySkuItem.goods_unit = item.spec_list[0].spec_name
            modifySkuItem.goods_weightUnit = item.goods_unit
            modifySkuItem.goods_singleWeight = item.spec_list[0].spec_value
            modifySkuItem.sn = item.sn
            modifySkuItem.hash_code = item.hash_code
            modifySkuItem.sku_id = item.sku_id
            modifySkuItem.isEdit = false
            const specItem = {
              spec_startValue: '',
              spec_price: '',
              sku_id: item.sku_id,
              isEdit: false
            }
            specItem.spec_startValue = item.spec_list[1].spec_value
            specItem.spec_price = item.price
            modifySkuItem.spec_list.push(specItem)
            console.log(modifySkuItem)
          } else {
            modifySkuList.push(modifySkuItem)
            tmpVal = item.spec_list[0].spec_value
            tmpUnit = item.spec_list[0].spec_name
            modifySkuItem = {
              goods_unit: item.spec_list[0].spec_name, // 自定义单位
              spec_name: '',
              goods_weightUnit: item.goods_unit,
              goods_singleWeight: item.spec_list[0].spec_value,
              hash_code: item.hash_code,
              spec_list: [],
              isEdit: false,
              weight: 0,
              sn: item.sn
            }
            const specItem = {
              spec_startValue: '',
              spec_price: '',
              sku_id: item.sku_id,
              isEdit: false
            }
            specItem.spec_startValue = item.spec_list[1].spec_value
            specItem.spec_price = item.price
            modifySkuItem.spec_list.push(specItem)
          }
        })
        modifySkuList.push(modifySkuItem)
        console.log(modifySkuList)
        return { skuList, modifySkuList }
      }
    },
    // 下拉
    merchantDropDown () {
      this.showPicker = true
    },
    // bindEdit (index) {
    //   console.log(this.modifySkuList[index].goods_singleWeight, this.goodWeight)
    //   if (this.modifySkuList[index].goods_singleWeight !== this.goodWeight) {
    //     this.modifySkuList[index].isEdit = true
    //   }
    // },
    bindEdit2 (index, index2) {
      this.modifySkuList[index].spec_list[index2].isEdit = true
    },
    bindEdit () {
      this.modifySkuList[0].isEdit = true
      this.modifySkuList[0].spec_list[0].isEdit = true
      // eslint-disable-next-line no-useless-escape
      this.spec_price = this.spec_price.replace(/[^\d\.]|^\./g, '').replace(/\.{2}/g, '.').replace(/^([1-9]\d*|0)(\.\d{1,2})(\.|\d{1})?$/, '$1$2').replace(/^0\d{1}/g, '0')
    },
    // 弹框 确认选择
    onConfirm (value, index) {
      // this.merchantVal = value
      this.showPicker = false
      this.category = this.categoryColumns[index]
    },
    // 选择单位
    selectUnit (index) {
      this.unitPicer = true
      this.modifyIndex = index
      this.goodWeight = this.modifySkuList[index].goods_singleWeight
      this.unit.goods_weightUnit = this.modifySkuList[index].goods_weightUnit
      this.weight = {
        id: this.modifySkuList[index].goods_weightUnit,
        text: this.unitToCN(this.modifySkuList[index].goods_weightUnit)
      }
    },
    // 单位确认
    onUnitConfirm () {
      const modifyIndex = this.modifyIndex
      this.modifySkuList.forEach(item => {
        item.goods_unit = this.unit.goods_unit
        item.goods_weightUnit = this.unit.goods_weightUnit
      })
      let pass = true
      if (this.modifySkuList.length < 2) {
        pass = true
      } else {
        try {
          this.modifySkuList.forEach((item, index) => {
            if (this.goodWeight === item.goods_singleWeight && index !== modifyIndex) {
              pass = false
              throw new Error('passFalse')
            }
          })
        } catch (e) {
          if (e.message !== 'passFalse') throw e
        }
      }
      if (pass) {
        this.modifySkuList[modifyIndex].goods_singleWeight = this.goodWeight
      } else {
        Toast('商品规格单品重量不能重复！')
        return
      }
      if (!this.unit.goods_unit) {
        Toast('请选择一个计量单位！')
        return
      }
      if (!this.goodWeight) {
        Toast('请输入正确的单品重量！')
        return
      }
      if (this.weight.id === '') {
        Toast('请选择单品重量单位！')
        return
      }
      this.unitPicer = false
      this.goodWeight = null
    },
    // 新增 或 减少 起批量价
    handleSpec (isAdd, index) {
      this.modifySpecValueModel = {
        sku_id: 0,
        spec_startValue: '',
        spec_price: ''
      }
      const item = this.modifySpecValueModel
      if (isAdd) {
        this.modifySkuList[index].spec_list.push(item)
        console.log(this.modifySkuList)
      } else {
        if (this.modifySkuList[index].spec_list.length === 1) {
          Toast('至少要有一条规格')
        } else {
          this.modifySkuList[index].spec_list.pop()
        }
      }
    },
    // 删除规格
    handleDelSpec (index) {
      Dialog.confirm({
        message: '确定删除该规格吗?'
      })
        .then(() => {
          console.log(index)
          this.modifySkuList.splice(index, 1)
        })
        .catch(() => {
          console.log('删除失败')
        })
    },
    // 新增规格
    handleAddSpec () {
      console.log('添加规格')
      const item = {
        goods_unit: this.unit.goods_unit, // 自然单位
        spec_name: '',
        goods_weightUnit: this.unit.goods_weightUnit, // 重量单位
        goods_singleWeight: null, // 单品重量
        sn: '',
        spec_list: [
          {
            spec_name: '',
            spec_startValue: '',
            spec_price: '',
            sku_id: 0
          }
        ],
        weight: 0
      }
      this.modifySkuList.push(item)
    },
    // 表单输入
    handleblur () {
      this.spec_price = this.spec_price.replace(/[^\d]/g, '')
    },
    // 将单位转化为中文
    unitToCN (str) {
      var _str = !str ? '选择单位' : str
      var unitObj = {
        KILO: '公斤',
        JIN: '斤',
        GRAMME: '克',
        TAEL: '两'
      }
      return unitObj[_str] ? unitObj[_str] : _str
    },
    // 获取分类
    getCategories () {
      this.$api.goods.category().then((res) => {
        if (this.info) {
          res.data.forEach(item => {
            if (item.shop_category_id === this.info.category_id) {
              this.category = item
            }
          })
        }
        console.log(res.data)
        this.categoryColumns = res.data
      })
    },
    // 拼装skulist
    concatSku (skulist) {
      const skuListApi = []
      const skuItem = {
        goods_id: null,
        goods_name: null,
        cost: 0,
        description: '',
        enable_quantity: 0,
        goods_type: '',
        goods_unit: '',
        is_wholesale_goods: 1,
        minimum_quantity: 0,
        mprice: 0,
        price: 0,
        quantity: 0,
        sn: '',
        sku_id: null,
        seller_id: null,
        seller_name: null,
        spec_list: [
          {
            spec_name: '',
            spec_value: '',
            spec_id: null,
            spec_image: '',
            spec_type: null,
            spec_value_id: null
          },
          {
            spec_name: '起批量',
            spec_value: '',
            spec_id: null,
            spec_image: '',
            spec_type: null,
            spec_value_id: null
          }
        ],
        template_id: 0,
        weight: 0
      }
      skuItem.goods_id = this.fromMode === 1 ? this.info.goods_id : null
      skuItem.goods_name = this.fromMode === 1 ? this.info.goods_name : this.goodModel.goods_name
      skuItem.cost = this.spec_price
      skuItem.price = this.spec_price
      skuItem.mprice = this.spec_price
      skuItem.goods_unit = 'KILO'
      // skuItem.hash_code = item.hash_code
      skuItem.seller_id = this.fromMode === 0 ? this.info.shopId : this.info.seller_id
      skuItem.seller_name = this.fromMode === 0 ? this.info.shopName : this.info.seller_name
      // skuItem.seller_id = 10000165
      // skuItem.seller_name = '粮油批发'
      skuItem.spec_list[0].spec_name = '公斤'
      skuItem.sn = this.randomString(32)
      skuItem.weight = 1
      skuItem.sku_id = this.fromMode === 0 || (this.goodModel.sku_list[0].spec_list[1].spec_value === '100' && this.fromMode === 1) ? null : skulist[0].spec_list[0].sku_id
      // skuItem.sku_id = null
      skuItem.spec_list[0].spec_value = 1
      skuItem.spec_list[1].spec_value = 1
      skuListApi.push(skuItem)
      this.skuList = skuListApi
      console.log(this.skuList)
    },
    // 上架或存入仓库
    handleUp (upStatus) {
      const param = this.goodModel
      if (this.fromMode === 0) {
        param.category_id = this.category.shop_category_id
        param.category_name = this.category.category_name
        param.farmers_market_id = this.info.marketId
        param.goods_id = null
        param.farmers_market_merchant_id = this.info.marketMerchantId
        param.seller_id = this.info.shopId
        param.seller_name = this.info.shopName
        // param.seller_id = 10000165
        // param.seller_name = '粮油批发'
        param.market_enable = upStatus
        console.log(this.modifyFileList)
        param.goods_gallery_list = this.modifyFileList
        param.ship_mode = 1
        this.concatSku(this.modifySkuList)
        param.sku_list = this.skuList
        console.log(param)
        delete param.variety
        const flag = this.checkForm()
        if (flag) {
          this.$api.goods.up(param, upStatus).then(res => {
            if (res.status === 200) {
              this.$router.push({
                name: 'Management',
                query: {
                  topActivetab: 1,
                  marketId: this.fromMode === 0 ? this.info.marketId : this.info.farmers_market_id, // 测试市场
                  shopId: this.fromMode === 0 ? this.info.shopId : this.info.seller_id,
                  marketMerchantId: this.fromMode === 0 ? this.info.marketMerchantId : this.info.farmers_market_merchant_id,
                  shopName: this.fromMode === 0 ? this.info.shopName : this.info.seller_name
                }
              })
            } else {
              Toast('新增失败！')
            }
          })
        }
      } else {
        param.category_id = this.category.shop_category_id
        param.farmers_market_id = this.info.farmers_market_id
        param.farmers_market_merchant_id = this.info.farmers_market_merchant_id
        param.market_enable = upStatus
        param.seller_id = this.info.seller_id
        param.seller_name = this.info.seller_name
        param.goods_gallery_list = this.modifyFileList
        // param.seller_id = 10000165
        // param.seller_name = '粮油批发'
        param.ship_mode = 1
        this.concatSku(this.modifySkuList)
        param.sku_list = this.skuList
        delete param.variety
        const flag = this.checkForm()
        if (flag) {
          this.$api.goods.up(param, upStatus).then(res => {
            if (res.status === 200) {
              this.$router.push({
                name: 'Management',
                query: {
                  topActivetab: 1,
                  marketId: this.fromMode === 0 ? this.info.marketId : this.info.farmers_market_id, // 测试市场
                  shopId: this.fromMode === 0 ? this.info.shopId : this.info.seller_id,
                  marketMerchantId: this.fromMode === 0 ? this.info.marketMerchantId : this.info.farmers_market_merchant_id,
                  shopName: this.fromMode === 0 ? this.info.shopName : this.info.seller_name
                }
              })
            } else {
              Toast('修改失败！')
            }
          })
        }
      }
    },
    // 选择计量单位
    chooseUnit (index) {
      this.unit_index = index
      this.unitColumns.forEach((item, idx) => {
        if (item.checked) {
          item.checked = false
        }
        if (idx === index) {
          item.checked = true
          this.unit.goods_unit = this.unitColumns[index].text
          this.unitText = this.unit.text
        }
      })
    },
    // 弹出计量单位选择
    popUpWeight () {
      this.weightPicker = true
    },
    // 选择重量单位
    onSelect (e) {
      this.weight = e
      this.unit.goods_weightUnit = e.id
    },
    // 自定义计量单位确认
    confirmCustomUnit () {
      this.unit = {
        text: ''
      }
      let isRepeat = false
      this.unitColumns.forEach((item) => {
        if (this.unitCustom.text === item.text) {
          Toast('不可添加相同的计量单位')
          isRepeat = true
        }
      })
      if (!isRepeat) {
        this.unit.goods_unit = this.unitCustom.text
        const newUnit = {
          text: this.unitCustom.text,
          checked: false
        }
        this.cusUnitList.push(newUnit)
        this.unitColumns.push(newUnit)
        localStorage.setItem('custom_unit', JSON.stringify(this.cusUnitList))
      }
    },
    // 检查表单
    checkForm () {
      let pass = true
      if (!this.goodModel.goods_name) {
        Toast('请填写商品名称！')
        pass = false
        return pass
      }
      if (!this.category.shop_category_id) {
        Toast('请选择商品类目！')
        pass = false
        return pass
      }
      if (this.fileList.length === 0) {
        Toast('请上传至少一张商品图片！')
        pass = false
        return pass
      }
      if (this.fileList[0].url === 'http://ynkg-ds.oss-cn-shenzhen.aliyuncs.com/images/sdfuguisd9435.png' && this.fileList.length === 1) {
        Toast('请上传商品图片并删除默认图片！')
        pass = false
        return pass
      }
      console.log(this.spec_price)
      if (!this.spec_price || parseInt(this.spec_price) === 0 || isNaN(this.spec_price)) {
        Toast('请设置正确的单价！')
        pass = false
        return pass
      }
      return pass
    },
    // 自动生成32位字符串
    randomString (length = 32) {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const maxPos = chars.length
      let _string = ''
      for (let i = 0; i < length; i++) {
        _string += chars.charAt(Math.floor(Math.random() * maxPos))
      }
      return _string
    },
    // 跳转选择商品
    toGoods () {
      const info = this.$route.params.info
      this.$router.push(
        {
          name: 'Goods',
          query: {
            info: info
          }
        })
    },
    // 删除自定义计量单位
    delCus () {
      if (this.unit_index < 8) {
        Toast('默认单位不可删除')
      } else {
        this.unitColumns.splice(this.unit_index, 1)
        this.cusUnitList.splice(this.unit_index - 8, 1)
        localStorage.setItem('custom_unit', JSON.stringify(this.cusUnitList))
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
