<template>
  <div class="guide">
    <i class="guide-btn" @click="onShowGuide"></i>

    <van-popup
      class="popup"
      v-model="guideVisible"
      :close-on-click-overlay="true"
      duration="0"
      :style="{ background: 'none' }"
    >
      <div class="popup-body">
        <div class="guide-setp guide-1" v-show="guideShow[0]">
          <div class="wrap">
            <img src="../../assets/images/guide-1.png" alt="" />
            <div class="next-btn" @click="nextStep(0)"></div>
          </div>
        </div>
        <div class="guide-setp guide-2" v-show="guideShow[1]">
          <div class="wrap">
            <img src="../../assets/images/guide-2.png" alt="" />
            <div class="next-btn" @click="nextStep(1)"></div>
          </div>
        </div>
        <div class="guide-setp guide-3" v-show="guideShow[2]">
          <div class="wrap">
            <img src="../../assets/images/guide-3.png" alt="" />
            <div class="next-btn" @click="nextStep(2)"></div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Popup } from 'vant'
export default {
  name: '',
  components: {
    [Popup.name]: Popup
  },
  data () {
    return {
      guideVisible: false,
      guideShow: {
        0: true,
        1: false,
        2: false
      }
    }
  },
  methods: {
    onShowGuide () {
      this.guideVisible = true
    },
    nextStep (index) {
      if (index === 2) {
        this.guideVisible = false
        this.guideShow = this.$options.data().guideShow
        return false
      }
      this.guideShow[index] = false
      this.guideShow[index + 1] = true
    }
  }
}
</script>

<style lang="less" scoped>
@import './index';
</style>
